import React from "react";

const Footer: React.FC<{}> = () => {
  return (
    <footer className="flex items-center gap-x-7 bg-[#2B7AF1]">
      <div className="px-12 py-4 flex items-center justify-center">
        <p className=" text-white leading-3">© 2024 povirka.mk.ua</p>
      </div>
    </footer>
  );
};

export default Footer;
