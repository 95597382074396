import React from "react";
import FullData from "../common/ProtocolInfo/FullData";

const Info = () => {
  return (
    <div>
      <FullData />
    </div>
  );
};

export default Info;
