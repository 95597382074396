import React from "react";

interface Props {
  img: string;
  text: string;
}

export const ServiceCard: React.FC<Props> = ({ img, text }) => {
  return (
    <div className="max-w-[300px] rounded-[10px] shadow-lg flex flex-col items-center justify-center text-center gap-y-4 p-8">
      <img src={img} />
      <p className="font-medium">{text}</p>
    </div>
  );
};
