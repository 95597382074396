import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { NavLink, Outlet } from "react-router-dom";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

function App() {
  return (
    <main className="flex flex-col ">
      <div className="w-full min-h-[calc(100vh-100px)] max-sm:h-full flex justify-center bg-[#eeeeee]">
        <div className="w-[1440px] bg-white shadow-md ">
          <Header />
          <Outlet />
          <Footer />
        </div>
      </div>
    </main>
  );
}

export default App;
