import React from "react";

interface Props {
  title: string;
  value: string;
  cStyle?: string;
}

const CardInfo: React.FC<Props> = ({ title, value, cStyle }) => {
  return (
    <div className="flex flex-col items-start justify-center rounded-lg bg-slate-100 bg-clip-border px-3 py-2">
      <p className="text-xs text-gray-400">{title}</p>
      <p
        className={`text-sm text-center w-full font-medium text-navy-700 dark:text-white ${cStyle}`}
      >
        {value}
      </p>
    </div>
  );
};

export default CardInfo;
