import React from "react";
import { NavLink } from "react-router-dom";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import {
  getDataThunk,
  setCounterNumber,
} from "../../store/project/projectsSlice";
import ProtocolInfo from "../common/ProtocolInfo";
import { ServiceCard } from "./components/ServiceCard";

import money from "../../assets/money.svg";
import documents from "../../assets/documents.svg";
import security from "../../assets/security.svg";
import time from "../../assets/time.svg";

import deal from "../../assets/deal.svg";
import review from "../../assets/review.svg";
import home from "../../assets/home.svg";

import counterImg from "../../assets/counterImg.png";

import { CounterInfo } from "../common/CounterInfo";

const Home = () => {
  const dispatch = useAppDispatch();
  const zvtNumber = useSelector(
    (state: RootState) => state.projects.counterNumber
  );
  const errorMessage = useSelector(
    (state: RootState) => state.projects?.errorMessage
  );
  const counter = useSelector((state: RootState) => state.projects?.counter);
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex flex-row">
        <div className="bg-white w-1/2 flex flex-row py-3 pl-12 gap-x-5 items-center">
          <div className="text-[#3c3a37] flex flex-col gap-y-3">
            <div className="pb-4">
              <h4 className="text-[25px] text-left pt-10 font-bold">
                База документiв
              </h4>
              <p className="w-[80%] font-medium">
                Будь ласка, введіть номер лічильника, для якого оформлено
                документ
              </p>
            </div>
            <div className="flex flex-row pb-4 w-[80%]">
              <input
                onChange={(e) => dispatch(setCounterNumber(e.target.value))}
                className="w-full border border-[#3c3a372f] py-2 pl-4 rounded-l-full"
                type="text"
                placeholder="Номер лічільника"
              />
              <button
                onClick={() => dispatch(getDataThunk(zvtNumber))}
                className="py-3 px-6 text-white shadow-md rounded-r-full bg-gradient-to-r from-[#FF7A00] to-[#FFB039]"
              >
                Пошук
              </button>
            </div>
            <div
              className={`text-red-400 font-bold ${
                !errorMessage && "invisible"
              }`}
            >
              Лічильника з таким номером не існує.
              <br />
              <span className="text-[#2B7AF1] font-bold">
                Номер для тесту: 234333
              </span>
            </div>
          </div>
        </div>
        <div className="bg-[#2B7AF1] w-1/2 flex flex-row py-3 gap-x-5 rounded-bl-xl items-center pb-10">
          {/* <h4 className="text-[22px] text-center pb-4 text-white">
          Система Електронного отримання та перевірки Результатів Повірки
          законодавчо регульованих засобів вимірювальної техніки, що перебувають
          в експлуатації
        </h4> */}
          <div className="-ml-20 pr-8">
            <img className="rounded-l-xl" src={counterImg} alt="counter" />
          </div>
        </div>
      </div>
      <div className="px-12">
        <CounterInfo />
      </div>

      <div className="px-12 flex flex-row justify-between">
        <ServiceCard
          img={documents}
          text="Укладаємо договори, надаємо звітні документи"
        />
        <ServiceCard
          img={money}
          text="Безготівковий розрахунок та оплата карткою на місці"
        />
        <ServiceCard img={time} text="Працюємо по вихідних" />
        <ServiceCard
          img={security}
          text="Надання звітів до місцевого водоканалу"
        />
      </div>
      <div className="px-12 pt-12 pb-12">
        <div className="flex flex-col gap-y-10">
          <p className="text-[#2B7AF1] text-3xl font-extrabold">Про нас</p>
          <div className="flex flex-row gap-x-20">
            <p className="w-6/12 font-medium">
              Наша Служба повірки лічильників має необхідні дозвільні документи,
              обладнання і кваліфікованих спеціалістів. Це гарантує ефективне
              виконання робіт без подальших проблем з документацією.
            </p>
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-row gap-x-4">
                <img src={deal} alt="deal" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">150+</p>
                  <p className="font-normal">Клієнтів</p>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <img src={review} alt="review" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">100+</p>
                  <p className="font-normal">Відгуків</p>
                </div>
              </div>

              <div className="flex flex-row gap-x-4">
                <img src={home} alt="home" />
                <div className="flex flex-col gap-y-2 justify-center">
                  <p className="text-[#2B7AF1] text-3xl font-extrabold">300+</p>
                  <p className="font-normal">Повірок</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
