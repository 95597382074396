import React from "react";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

const FullData = () => {
  const counter = useSelector((state: RootState) => state.projects?.counter);
  return (
    <div className="shadow overflow-hidden rounded border-b border-gray-200 w-full">
      <table className=" bg-white">
        <thead className="bg-green-600 text-white">
          <tr>
            <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">
              Назва
            </th>
            <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">
              Значення
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Account</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Account}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Apartment</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Apartment}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Auditdate</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Auditdate}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Статус</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Result === "GUD" ? "Придатний" : "Не придатний"}
            </td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Тип лічильника</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.serviceType === 0
                ? "Лічильник холодної води"
                : "Лічильник гарячої води"}
            </td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Номер лічильника</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.CounterNumber}
            </td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Building</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Building}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">CentrO</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.CentrO}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">City</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.City}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">CountOfTests</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.CountOfTests}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Customer</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Customer}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">CustomerID</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.CustomerID}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Date</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Date}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Diametr</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Diametr}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">District</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.District}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">DocNumber</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.DocNumber}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">EDRPOU</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.EDRPOU}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">FileNumber</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.FileNumber}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">FullStandName</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.FullStandName}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Id_pc</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Id_pc}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">InstallmentNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.InstallmentNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">IsLegal</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.IsLegal}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Limits</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Limits}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Limits2</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Limits2}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Liter</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Liter}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Middlename</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Middlename}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Name</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Name}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">NewDate</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.NewDate}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Note</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Note}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">OMiddlename</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.OMiddlename}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">OName</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.OName}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">OSurname</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.OSurname}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">PrintReady</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.PrintReady}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Producer</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Producer}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">RLatitude</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.RLatitude}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">RLongitude</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.RLongitude}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Raspologenie</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Raspologenie}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Res1</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Res1}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Res2</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Res2}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Res3</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Res3}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Res4</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Res4}</td>
          </tr>
          <tr>
            <td className="w-1/3 text-left py-3 px-4">Res5</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Res5}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Reserve</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Reserve}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Street</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Street}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">StreetID</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.StreetID}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Surname</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Surname}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">TVozd</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.TVozd}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">TelNumber</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.TelNumber}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">TelNumber2</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.TelNumber2}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Temperature</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Temperature}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">TypeID</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.TypeID}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Vlagnost</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Vlagnost}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Year</td>
            <td className="w-1/3 text-left py-3 px-4">{counter?.Year}</td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test10AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test10ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test10actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test10evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test10initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test10testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test10testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test10volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test10volumeOfStandard}
            </td>
          </tr>

          {/* // */}

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test11AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test11ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test11actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test11evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test11initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test11testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test11testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test11volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test11volumeOfStandard}
            </td>
          </tr>

          {/* // */}

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test20AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test20ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test20actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test20evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test20initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test20testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test20testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test20volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test20volumeOfStandard}
            </td>
          </tr>

          {/* // */}

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test21AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test21ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test21actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test21evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test21initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test21testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test21testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test21volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test21volumeOfStandard}
            </td>
          </tr>

          {/* // */}

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test30AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test30ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test30actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test30evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test30initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test30testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test30testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test30volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test30volumeOfStandard}
            </td>
          </tr>

          {/* // */}

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test31AllowableError</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31AllowableError}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test31ConsuptionStatus
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31ConsuptionStatus}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test31actualConsumption
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31actualConsumption}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test31evdValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31evdValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test31initialValue</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31initialValue}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test31testDuration</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31testDuration}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">Test31testNumber</td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31testNumber}
            </td>
          </tr>

          <tr>
            <td className="w-1/3 text-left py-3 px-4">
              Test31volumeOfStandard
            </td>
            <td className="w-1/3 text-left py-3 px-4">
              {counter?.Test31volumeOfStandard}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FullData;
